import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Cart from "./components/Cart/Cart";
import Dresser from "./components/Dresser/Dresser";
import CheckoutForm from "./components/CheckoutForm/CheckoutForm";
import Footer from "./components/Footer/Footer";
import MainNavbar from "./components/Navbar/Navbar";
import Newsletter from "./components/Newsletter/Newsletter";
import Productdetail from "./components/Productdetails/Productdetails";
import { useStateContext } from "./context/StateContext";
import Collectionpage from "./pages/Collectionpage/Collectionpage";
import Featuredpage from "./pages/Featuredpage/Featuredpage";
import Homepage from "./pages/Homepage/Homepage";
import ProductCategoryPage from "./pages/ProductCategoryPage/ProductCategorypage";
import serverUrl from "./server";
import "./App.css";

const App = () => {
  const {
    cartItems,
    products,
    setProducts,
    setCollections,
    setCategories,
    banners,
    setBanners,
    deliveryPrices,
    setDeliveryPrices,
  } = useStateContext();
  useEffect(() => {
    if (products.length < 1) {
      async function fetchProducts(link) {
        await fetch(`${link}`, {
          headers: {
            "Content-Type": "application/json",
            origin: "http://localhost:8080",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            const fetchedproducts = data.map((product, index) => {
              return {
                id: +index + 1,
                _id: product._id,
                productCategory: product.productCategory,
                productCollection: product.productCollection,
                productImages: product.productImages,
                productName: product.productName,
                productPrice: product.productPrice,
                productType: product.productType,
                isBestSelling: product.isBestSelling,
              };
            });
            setProducts(fetchedproducts);
            console.log(fetchedproducts);
            const productsData = data.map((product, index) => {
              return {
                id: +index + 1,
                _id: product._id,
                productCategory: product.productCategory,
                productCollection: product.productCollection,
                productImages: product.productImages,
                productName: product.productName,
                productPrice: product.productPrice,
                productType: product.productType,
                isBestSelling: product.isBestSelling,
              };
            });
            // Group products by collection name
            let groupedProducts = {};
            let idCounter = 1;
            productsData.forEach((product) => {
              if (!groupedProducts[product.productCollection]) {
                groupedProducts[product.productCollection] = {
                  id: idCounter,
                  collectionName: product.productCollection,
                  products: [product],
                };
                idCounter++;
              } else {
                groupedProducts[product.productCollection].products.push(
                  product
                );
              }
            });
            const groupedProductsByCategoryArray =
              Object.values(groupedProducts);
            console.log(groupedProductsByCategoryArray);
            setCollections(groupedProductsByCategoryArray);
            // Group products by category name
            let groupedCategoryProducts = {};
            let categoryIdCounter = 1;
            productsData.forEach((product) => {
              if (!groupedCategoryProducts[product.productCategory]) {
                groupedCategoryProducts[product.productCategory] = {
                  id: categoryIdCounter,
                  categoryName: product.productCategory,
                  products: [product],
                };
                categoryIdCounter++;
              } else {
                groupedCategoryProducts[product.productCategory].products.push(
                  product
                );
              }
            });
            const groupedProductsByCategory = Object.values(
              groupedCategoryProducts
            );
            console.log(groupedProductsByCategory);
            setCategories(groupedProductsByCategory);
          });
      }
      fetchProducts(`${serverUrl}/api/product`);
    }
    if (banners.length < 1) {
      async function fetchBanners(link) {
        await fetch(`${link}`, {
          headers: {
            "Content-Type": "application/json",
            origin: "http://localhost:8080",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            const fetchedBanners = data.map((banner, index) => {
              return {
                id: +index + 1,
                _id: banner._id,
                bannerName: banner.bannerName,
                bannerImages: banner.bannerImages,
                bannerDescription: banner.bannerDescription,
                bannerCollection: banner.bannerCollection,
              };
            });
            setBanners(fetchedBanners);
          });
      }
      fetchBanners(`${serverUrl}/api/banner`);
    }
    if (deliveryPrices.length < 2) {
      async function fetchDeliveryPrices(link) {
        await fetch(`${link}`, {
          headers: {
            "Content-Type": "application/json",
            origin: "http://localhost:8080",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            const fetchedPrices = data?.map((price, index) => {
              return {
                id: +index + 1,
                _id: price._id,
                ...price,
                disabled: false,
                selected: false,
              };
            });
            setDeliveryPrices([...deliveryPrices, ...fetchedPrices]);
          });
      }
      fetchDeliveryPrices(`${serverUrl}/api/delivery-prices`);
    }
  }, [
    products.length,
    setProducts,
    setCollections,
    products,
    setCategories,
    deliveryPrices.length,
    deliveryPrices,
    setDeliveryPrices,
    setBanners,
    banners.length,
  ]);
  return (
    <>
      <MainNavbar />
      {products.length > 0 ? (
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/collections" element={<Collectionpage />} />
          <Route path="/featured-brands" element={<Featuredpage />} />
          <Route
            path="/category/:categoryName"
            element={<ProductCategoryPage />}
          />
          <Route
            path="/collection/:collectionName"
            element={<ProductCategoryPage />}
          />
          <Route path="/:productName/details" element={<Productdetail />} />
          <Route path="/cart" element={<Cart />} />
          {cartItems.length >= 1 && (
            <Route path="/checkout" element={<CheckoutForm />} />
          )}
        </Routes>
      ) : (
        <div
          className="py-4"
          style={{
            color: "#b8a06a !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Fetching products 😁😁 ... &nbsp;&nbsp;{" "}
          <CircularProgress color="inherit" sx={{ color: "#b8a06a" }} />
        </div>
      )}
      <Newsletter />
      <Footer />
      <Dresser />
    </>
  );
};

export default App;

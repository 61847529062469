// This is a JavaScript code that defines a React component to manage the state of the shopping cart. The code creates a context and exports it as StateContext. The context provides state values and functions that can be accessed by its child components.

// The state variables managed by this component include products, collections, categories, banners, cartItems, totalPrice, totalQuantities, and taxAndVat. There are also helper functions such as calculateTax, increaseQty, decreaseQty, onAddToCart, and onRemove.

// The useEffect hooks are used to trigger certain actions when the state changes. For example, useEffect(() => {}, [taxAndVat]) will run when taxAndVat changes.

// The code also uses the localStorage API to persist data such as the shopping cart items and the total price. When the component is mounted, it checks if there are any items in the cart and initializes the state accordingly.

// Finally, the code uses the toast library to display notifications when certain actions are performed.

import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";

const Context = createContext();
export const StateContext = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [banners, setBanners] = useState([]);
  const [deliveryPrices, setDeliveryPrices] = useState([
    {
      priceName: "Select Shipping Option",
      priceAmount: "",
      disabled: true,
      selected: true,
    },
  ]);
  const calculateTax = (totalPrice) => {
    const feeCap = 2000,
      decimalFee = 0.015,
      flatFee = 100;
    let finalPrice;
    // To calculate tax Calculate applicable fees: Based on your fee structure, you calculate the fees the customer would be charged if you were not passing these fees to them. This is because of the transaction fee cap.
    const applicableFee = +decimalFee * +totalPrice + +flatFee;
    console.log(applicableFee);
    if (applicableFee > feeCap) {
      finalPrice = totalPrice + feeCap;
      return +finalPrice;
    } else {
      // Final Amount = ({Price} / (1 - {Decimal Fee})) + 0.01
      finalPrice = (totalPrice + flatFee) / (1 - decimalFee);
      finalPrice = finalPrice + 0.01;
      return +finalPrice;
    }
  };

  localStorage.getItem("cartItems") === null &&
    localStorage.setItem("cartItems", JSON.stringify([]));
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) ?? []
  );
  let totalPriceDeterminer =
    JSON.parse(localStorage.getItem("cartItems")) === null
      ? 0
      : JSON.parse(localStorage.getItem("cartItems")).length >= 1 &&
        JSON.parse(localStorage.getItem("cartItems"))
          ?.map((item) => item.productPrice * item.quantity)
          .reduce((accumulator, currentValue) => accumulator + currentValue);
  const [totalPrice, setTotalPrice] = useState(totalPriceDeterminer);
  const [taxAndVat, setTaxAndVat] = useState(
    Math.round(calculateTax(totalPrice) - totalPrice)
  );
  useEffect(() => {}, [taxAndVat]);

  useEffect(() => {
    setTaxAndVat(Math.round(calculateTax(totalPrice) - totalPrice));
  }, [totalPrice]);

  let totalQuantitiesDeterminer =
    JSON.parse(localStorage.getItem("cartItems")) === null
      ? 0
      : JSON.parse(localStorage.getItem("cartItems")).length >= 1 &&
        JSON.parse(localStorage.getItem("cartItems"))
          ?.map((item) => item.quantity)
          ?.reduce((accumulator, currentValue) => accumulator + currentValue);
  const [totalQuantities, setTotalQuantities] = useState(
    totalQuantitiesDeterminer
  );
  const [qty, setQty] = useState(1);
  let foundProduct;
  let index;
  // Increase Quantity
  const increaseQty = () => {
    setQty((prevQty) => prevQty + 1);
  };
  // Decrease Quantity
  const decreaseQty = () => {
    setQty((prevQty) => {
      if (prevQty - 1 < 1) return 1;
      return prevQty - 1;
    });
  };
  // Adding to Cart
  const onAddToCart = (
    product,
    quantity,
    color,
    colorName,
    size,
    len,
    type
  ) => {
    const CheckProductInCart = cartItems.find(
      (item) => item.productName === product.productName
    );

    if (CheckProductInCart) {
      const updatedCartItems = cartItems.map((cartItem) => {
        if (cartItem.id === product.id) {
          return {
            ...cartItem,
            quantity: cartItem.quantity + quantity,
            color,
            colorName,
            size,
            len: len ? len : cartItem.len,
            type: type ? type : cartItem.type,
          };
        }
        return cartItem;
      });
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      setCartItems(JSON.parse(localStorage.getItem("cartItems")));
      setTotalPrice(
        (prevTotalPrice) => prevTotalPrice + product.productPrice * quantity
      );
      setTaxAndVat(Math.round(calculateTax(totalPrice) - totalPrice));

      setTotalQuantities(
        (prevTotalQuantities) => prevTotalQuantities + quantity
      );
    } else {
      if (quantity && color && colorName && size) {
        const newCartItem = {
          ...product,
          quantity,
          color,
          colorName,
          size,
          len: len ? len : "",
          type: type ? type : "",
        };
        localStorage.setItem(
          "cartItems",
          JSON.stringify([...cartItems, newCartItem])
        );
        setCartItems(JSON.parse(localStorage.getItem("cartItems")));
        setTotalPrice(
          (prevTotalPrice) => prevTotalPrice + product.productPrice * quantity
        );
        setTaxAndVat(Math.round(calculateTax(totalPrice) - totalPrice));

        setTotalQuantities(
          (prevTotalQuantities) => prevTotalQuantities + quantity
        );
        toast.success(`${quantity} ${product.productName} added successfully`);
      } else {
        toast.error("Can't add item to cart");
      }
    }
  };
  // Item Removal Function
  const onRemove = (productName) => {
    localStorage.setItem(
      "cartItems",
      JSON.stringify(
        JSON.parse(localStorage.getItem("cartItems")).filter(
          (item) => item.productName !== productName
        )
      )
    );
    setCartItems(JSON.parse(localStorage.getItem("cartItems")));
    setTotalQuantities(
      JSON.parse(localStorage.getItem("cartItems")) === null
        ? 0
        : JSON.parse(localStorage.getItem("cartItems")).length >= 1 &&
            JSON.parse(localStorage.getItem("cartItems"))
              ?.map((item) => item.quantity)
              ?.reduce(
                (accumulator, currentValue) => accumulator + currentValue
              )
    );
    setTotalPrice(
      JSON.parse(localStorage.getItem("cartItems")) === null
        ? 0
        : JSON.parse(localStorage.getItem("cartItems")).length >= 1 &&
            JSON.parse(localStorage.getItem("cartItems"))
              ?.map((item) => item.price * item.quantity)
              .reduce((accumulator, currentValue) => accumulator + currentValue)
    );
    setTaxAndVat(Math.round(calculateTax(totalPrice) - totalPrice));
  };
  // Changing items quantity in cart
  const toggleCartItemQuantity = (productName, value) => {
    foundProduct = JSON.parse(localStorage.getItem("cartItems")).find(
      (cartItem) => cartItem.productName === productName
    );
    index = JSON.parse(localStorage.getItem("cartItems")).findIndex(
      (cartItem) => cartItem.productName === productName
    );
    let newCartItems = JSON.parse(localStorage.getItem("cartItems")).filter(
      (cartItem) => cartItem.productName !== productName
    );
    if (value === "increment") {
      newCartItems.splice(index, 0, {
        ...foundProduct,
        quantity: foundProduct.quantity + 1,
      });
      localStorage.setItem("cartItems", JSON.stringify([...newCartItems]));
      setCartItems(JSON.parse(localStorage.getItem("cartItems")));
      setTotalPrice(
        JSON.parse(localStorage.getItem("cartItems")) === null
          ? 0
          : JSON.parse(localStorage.getItem("cartItems")).length >= 1 &&
              JSON.parse(localStorage.getItem("cartItems"))
                ?.map((item) => item.productPrice * item.quantity)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue
                )
      );
      setTaxAndVat(Math.round(calculateTax(totalPrice) - totalPrice));
      setTotalQuantities(
        JSON.parse(localStorage.getItem("cartItems")) === null
          ? 0
          : JSON.parse(localStorage.getItem("cartItems")).length >= 1 &&
              JSON.parse(localStorage.getItem("cartItems"))
                ?.map((item) => item.quantity)
                ?.reduce(
                  (accumulator, currentValue) => accumulator + currentValue
                )
      );
    } else if (value === "decrement") {
      if (foundProduct.quantity > 1) {
        newCartItems.splice(index, 0, {
          ...foundProduct,
          quantity: foundProduct.quantity - 1,
        });
        localStorage.setItem("cartItems", JSON.stringify([...newCartItems]));
        setCartItems(JSON.parse(localStorage.getItem("cartItems")));
        setTotalPrice(
          JSON.parse(localStorage.getItem("cartItems")) === null
            ? 0
            : JSON.parse(localStorage.getItem("cartItems")).length > 0 &&
                JSON.parse(localStorage.getItem("cartItems"))
                  ?.map((item) => item.productPrice * item.quantity)
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                  )
        );
        setTaxAndVat(Math.round(calculateTax(totalPrice) - totalPrice));
        setTotalQuantities(
          JSON.parse(localStorage.getItem("cartItems")) === null
            ? 0
            : JSON.parse(localStorage.getItem("cartItems")).length >= 1 &&
                JSON.parse(localStorage.getItem("cartItems"))
                  ?.map((item) => item.quantity)
                  ?.reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                  )
        );
      } else {
        onRemove(productName);
      }
    }
  };

  return (
    <Context.Provider
      value={{
        cartItems,
        totalPrice,
        totalQuantities,
        taxAndVat,
        qty,
        increaseQty,
        decreaseQty,
        onAddToCart,
        setTotalPrice,
        toggleCartItemQuantity,
        setCartItems,
        setTotalQuantities,
        setTaxAndVat,
        products,
        setProducts,
        collections,
        setCollections,
        categories,
        setCategories,
        banners,
        setBanners,
        deliveryPrices,
        setDeliveryPrices,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export const useStateContext = () => useContext(Context);
